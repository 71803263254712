import React from 'react'
import PropTypes from 'prop-types'
// import { graphql } from 'gatsby';
// import classNames from 'classnames';
import Layout from 'components/layout'
import Head from 'components/head'
// import Box from 'components/box';
// import PageBlocks from 'components/pageBlocks';
import 'style/cases.scss'

const CaseTemplate = ({ pageContext }) => {
  const fields = pageContext.fields

  console.log({ fields })

  return (
    <Layout locale="nl">
      <Head
        locale={pageContext.locale}
        pageTitle={fields.title}
        pageDescription={fields.fields['Meta']}
        imageUrl={
          'https://cms.burobork.nl/images/' +
          fields.fields['Feature Afbeelding'][0].reference
        }
      />
      <script type="application/ld+json">
        {JSON.stringify([
          {
            '@context': 'http://schema.org',
            '@type': 'Article',
            url: 'https://bukazu.com/klantcases/' + fields.url,
            content: fields.fields['Long title'],
            image:
              'https://cms.burobork.nl/images/' +
              fields.fields['Feature Afbeelding'][0].reference,
          },
        ])}
      </script>
      <div className="case main_article">
        <div className="content_wrap">
          <div className="content">
            <article
              className=""
              itemScope="itemscope"
              itemType="http://schema.org/Article"
              itemProp="articleBody"
              itemID={'https://bukazu.com/klantcases/' + fields.url}
            >
              <section className="hero">
                <div className="hero-image">
                  <img
                    src={`//cdn.burobork.nl/${fields.fields['Feature Afbeelding'][0].reference}`}
                    alt="Online reserveringssysteem voor bootverhuur BUKAZU"
                  />
                </div>
                <div className="hero-header">
                  <h1 className="bukazu-h1-header">{fields.fields['Long title']}</h1>
                </div>
              </section>
              <div className="article-content">
                <div
                  className="article-content-text bukazu-p"
                  dangerouslySetInnerHTML={{ __html: fields.fields.Intro.nl }}
                />
                <div
                  className="case-content-text bukazu-p-x-medium"
                  dangerouslySetInnerHTML={{ __html: fields.fields.Text.nl }}
                />
              </div>
            </article>
          </div>
        </div>
      </div>
    </Layout>
  )
}

CaseTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default CaseTemplate
